import React, { Component } from 'react';
import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton } from 'video-react';

export default class VideoReactSeekControl extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
    }

    convertTimeToSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    }

    seekToTime = (startTime) => {
        const player = this.player.current;
        if (player) {
            const seekTime = this.convertTimeToSeconds(startTime);
            player.seek(seekTime);
            player.play();

            if (player.paused) {
                player.play();
            }
        }
    }

    render() {
        const { videoUrl, thumbnail } = this.props;

        return (
            <Player
                ref={this.player}
                playsInline
                src={videoUrl}
                poster={thumbnail}
                onLoadedMetadata={() => {
                    // Optional: Notify parent component that video is ready
                    if (this.props.onVideoReady) {
                        this.props.onVideoReady(this.player.current);
                    }
                }}
            >
                <BigPlayButton position="center" />
                <ControlBar>
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                </ControlBar>
            </Player>
        );
    }
}