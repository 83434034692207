import React, { PureComponent } from 'react';
import './modal.css';

export default class CustomModal extends PureComponent {
    componentDidMount() {
        if (this.props.show) {
            this.start_listner();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show && !this.props.show) {
            this.end_listner();
        } else if (!prevProps.show && this.props.show) {
            this.start_listner();
        }
    }

    keyDown = (e) => {
        if (e.keyCode === 27) {
            this.props.close();
        }
    };

    start_listner = () => {
        document.addEventListener('keydown', this.keyDown);
    };

    end_listner = () => {
        document.removeEventListener('keydown', this.keyDown);
    };

    stop_propagation = (e) => e.stopPropagation();

    render() {
        if (this.props.show) {
            const cus_inner_style =
                !!this.props.inner_style && Object.keys(this.props.inner_style).length !== 0
                    ? this.props.inner_style
                    : {};
            return (
                <div className="custom-modal-container" onClick={this.props.close}>
                    {this.props?.showCloseBtn ? (
                        <div
                            className={`${this?.props?.isFullWidth ? 'custom-modal-inner-container-myt' : 'custom-modal-inner-container-close'} relative`}
                            onClick={this.stop_propagation}
                            style={cus_inner_style}
                        >
                            {!this.props.customClose ? (
                                <img
                                    className="absolute top-0"
                                    src="https://images.myyogateacher.com/teacher_web_app/ic_cross@3x.svg"
                                    alt="c"
                                    onClick={this.props.close}
                                    width={12}
                                />
                            ) : null}
                            {this.props.children}
                        </div>
                    ) : (
                        <div
                            className={`${this?.props?.isFullWidth ? 'custom-modal-inner-container-myt' : 'custom-modal-inner-container w-full max-w-[900px]'}`}
                            onClick={this.stop_propagation}
                            style={cus_inner_style}
                        >
                            <img
                                src="https://images.myyogateacher.com/teacher_web_app/ic_cross@3x.svg"
                                alt="c"
                                onClick={this.props.close}
                            />
                            {this.props.children}
                        </div>
                    )}
                </div>
            );
        }
        return null;
    }
}
