import React, { PureComponent } from 'react';
import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton } from 'video-react';

export default class CustomVideoReact extends PureComponent {
    render() {
        return (
            <Player playsInline src={this.props.video_url}>
                <BigPlayButton position="center" />
                <ControlBar>
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                </ControlBar>
            </Player>
        );
    }
}
