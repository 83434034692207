import React, { useEffect, useState, useRef } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import { get_api_url } from '../../../../utils/urls';
import { get_api } from '../../../../redux/api_funcs';
import CustomModal from '../../../util_components/custom_modal';
import VideoReactSeekControl from '../../../util_components/video_react_seek_control';


const ChapterTitle = ({ title }) => {
    return (
        <h3
            className={`mb-2 font-semibold text-md line-clamp-2`}
        >
            {title}
        </h3>
    );
};

const ChapterTags = ({ tags }) => {
    return (
        <div className="space-y-2">
            <h4 className="font-medium text-xs text-gray-700">Chapter tags</h4>
            <div className="flex flex-wrap gap-2">
                {tags.map((tag, tagIndex) => (
                    <span
                        key={tagIndex}
                        className="px-2 py-0.5 bg-blue-200 text-blue-600 text-sm rounded-full"
                    >
                        {tag}
                    </span>
                ))}
            </div>
        </div>
    );
};

const VideoChapters = (props) => {

    const [sessionUUID, setSessionUUID] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [vcData, setVcData] = useState({});
    const [showGeminiJson, setShowGeminiJson] = useState(false);
    const [activeChapter, setActiveChapter] = useState(null);
    const [showPromptModal, setShowPromptModal] = useState(false);

    const videoPlayerRef = useRef(null);


    useEffect(() => {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const uuid = url.searchParams.get('id') || url.searchParams.get('session_uuid');
        if (uuid) {
            setSessionUUID(uuid)
        }
    }, []);


    useEffect(() => {
        if (sessionUUID) {
            const url = get_api_url(`/v2/crons-tasks/poc/session/${sessionUUID}/chapters`, false, true)
            get_api(url, true)
                .then((response) => {
                    setVcData(response?.data?.data);
                    setActiveChapter(response?.data?.data?.result?.chapters[0])
                    setLoading(false);
                })
                .catch((err) => {
                    setError("Video chapter not available")
                })
        }
    }, [sessionUUID]);

    const scrollChapters = (direction) => {
        const container = document.getElementById('chapters-container');
        const scrollAmount = 200;
        if (container) {
            container.scrollBy({
                left: direction === 'right' ? scrollAmount : -scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    const toggleGeminiResult = () => {
        setShowGeminiJson(!showGeminiJson);
    };

    const renderGeminiResult = () => {
        return (
            <>
                <h2 className='font-semibold text-center text-xl mb-2'>Video Chapter Gemini Result</h2>
                <hr className='border-black mb-4' />
                <pre>{JSON.stringify(vcData?.gemini, null, 2)}</pre>
            </>
        )
    }


    const handleChapterClick = (chapter) => {
        setActiveChapter(chapter);
        if (videoPlayerRef.current) {
            videoPlayerRef.current.seekToTime(chapter.start_time);
        }
    }

    const formatTime = (timeString) => {
        const parts = timeString.split(':');

        if (parts.length === 2) {
            // mm:ss format
            return timeString;
        }

        if (parts.length === 3) {
            // hh:mm:ss format
            const [hours, minutes, seconds] = parts;

            if (hours === '00') {
                // If hours is 00, return mm:ss
                return `${minutes}:${seconds}`;
            }

            return timeString;
        }

        return timeString;
    };

    const togglePromptInfo = () => {
        setShowPromptModal(!showPromptModal)
    }

    const renderPromptInfo = () => {
        return <div className="p-2" >
            <div className="space-y-4 overflow-y-auto">
                <h2 className='text-center text-lg underline font-bold'>Prompt Information</h2>
                {vcData?.prompt_type_desc ?
                    <div className="text-sm text-gray-700"> <b><u>Prompt Type Description:</u></b> {vcData?.prompt_type_desc}</div>
                    : null}
                {vcData?.system_instruction ?
                    <div className="text-sm text-gray-700"><b><u>System instruction:</u></b> <pre className='mt-2'>{vcData?.system_instruction}</pre></div>
                    : null}
            </div>
        </div >
    }

    const GoBack = () => {
        return props.history.goBack();
    }



    if (error) {
        return <div className="common-page-container">
            <div className="profile-go-back" onClick={GoBack}>
                <img
                    src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
                    alt="back"
                />
                <span>Back</span>
            </div>
            <h2 className='text-lg font-semibold text-center mt-6'>{error}</h2>
        </div>
    }
    return (
        <>
            {!loading &&
                <div className="common-page-container">
                    <div className="max-w-full mx-auto lg rounded-lg overflow-hidden">
                        <div className="profile-go-back" onClick={GoBack}>
                            <img
                                src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
                                alt="back"
                            />
                            <span>Back</span>
                        </div>
                        <div className="text-center bg-white border-b">
                            <h1 className="text-blue-600 font-bold">Session Video Chapter</h1>
                        </div>
                        {/* Main Content */}
                        <div className="flex">
                            {/* Video Section */}
                            <div className="w-full p-4 pb-24">
                                <div className="relative rounded-lg overflow-hidden">
                                    <VideoReactSeekControl
                                        videoUrl={vcData?.replay_url}
                                        ref={videoPlayerRef}
                                        thumbnail={vcData?.gallery_view_replay_url || vcData?.speaker_view_replay_url || vcData?.thumb_url}
                                    />
                                </div>

                                {/* Chapters */}
                                <div className="mt-4">
                                    <div className="flex flex-wrap gap-2 mt-4">
                                        <span className='text-sm p-2 font-semibold'>Video tags</span> {vcData?.tags.map((tag, index) => (
                                            <span
                                                key={index}
                                                className="text-sm p-2 text-blue-600 rounded-full  bg-blue-100 transition-colors"
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="flex items-center justify-between mt-4 mb-2">
                                        <h2 className='font-semibold text-xl'>Summary and smart chapters</h2>
                                        <div className="flex gap-2">
                                            <button
                                                className=" bg-white/90 text-blue-600 h-8 text-sm text-center leading-3 px-4 m-2 hover:bg-blue-50"
                                                onClick={togglePromptInfo}
                                            >
                                                Prompt Information
                                            </button>
                                            <button
                                                className=" bg-white/90 text-blue-600 h-8 text-sm text-center leading-3 px-4 m-2 hover:bg-blue-50"
                                                onClick={toggleGeminiResult}
                                            >
                                                View Gemini Result
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <h3 className='font-bold mt-2'>Video Summary</h3>
                                        <p className='leading-6'>{vcData?.summary || "Summary not available"}</p>
                                    </div>
                                    <h3 className='font-bold'>Smart Chapters</h3>
                                    <div className="relative mt-4">
                                        <button
                                            onClick={() => scrollChapters('left')}
                                            className="absolute -left-4 top-1/2  text-black border-black -translate-y-1/2 z-10 bg-white/90 rounded-full p-1 shadow-md hover:bg-gray-200 hover:text-black"
                                        >
                                            <ChevronLeft className="w-5 h-5" />
                                        </button>
                                        <div
                                            id="chapters-container"
                                            className="flex gap-2 overflow-x-auto scroll-smooth hide-scrollbar pl-8 pr-8 w-[95%] cursor-pointer"
                                            style={{
                                                scrollbarWidth: 'none',
                                                msOverflowStyle: 'none',
                                                WebkitOverflowScrolling: 'touch'
                                            }}
                                        >
                                            {vcData?.result?.chapters?.map((chapter, index) => (
                                                <div
                                                    key={chapter.start_time}
                                                    className={`flex-shrink-0 rounded-lg h-full overflow-hidden ${activeChapter?.start_time === chapter.start_time ? 'ring-2 ring-blue-500 bg-blue-100' : 'bg-grat-100'}`}
                                                    onClick={() => handleChapterClick(chapter)}
                                                >
                                                    <div className="w-[11rem] relative">
                                                        <img
                                                            src={chapter.sthumb}
                                                            alt={`Chapter ${index + 1} thumbnail`}
                                                            className="w-full h-18 object-cover cursor-pointer"
                                                        />
                                                        <div className={`p-2 h-full py-4 ${activeChapter?.start_time === chapter.start_time ? 'bg-blue-100' : 'bg-gray-100'
                                                            }`}>
                                                            <div className="text-xs text-gray-800 mb-2">From {formatTime(chapter.start_time)}</div>
                                                            <ChapterTitle title={chapter.title} />
                                                            <ChapterTags tags={chapter.tags} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <button
                                            onClick={() => scrollChapters('right')}
                                            className="absolute right-0 top-1/2 text-black border-black -translate-y-1/2 z-10 bg-white/90 rounded-full p-1 shadow-md hover:bg-gray-200 hover:text-black"
                                        >
                                            <ChevronRight className="w-5 h-5" />
                                        </button>
                                    </div>
                                    <div className="bg-gray-100 rounded-lg p-4 mt-6">
                                        <h3 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
                                            {activeChapter?.title}
                                        </h3>
                                        <p className='leading-6 text-md'>{activeChapter?.summary || "Chapter summary not available"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CustomModal show={showGeminiJson} close={toggleGeminiResult}>
                            {renderGeminiResult()}
                        </CustomModal>
                        <CustomModal show={showPromptModal} close={togglePromptInfo}>
                            {renderPromptInfo()}
                        </CustomModal>
                    </div>
                </div>
            }
        </>
    );
};

export default VideoChapters;